import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import title_image from '../images/titles/digital-consulting-services.jpg'


const pageTitle = "Digital Consulting Services";
const pageSlug = "digital-consulting-services";


class DigitalConsultingServices extends React.Component{
    render(){
      return (
        <Layout>
          <section
            className="section page-title"
            style={{ backgroundImage: `url(${title_image})` }}
          >
            <div className="overlay"></div>
            <div className="container">
              <h1 className="title">{pageTitle}</h1>
              <h6 className="subtitle">
              Together we can develop your digital strategy and build it, customize your customer experience and make your marketing campaign a success.
              </h6>
            </div>
          </section>

          <section
            id="p_technology_services"
            className="section static-page page-content"
          >
            <div className="container">
              <div className="columns">
                <div className="column is-12 content-area">

                  <h3>Our strategic consulting services will support you, whether you’re planning your digital strategy or have had one running for some time. By analyzing your situation, planning your course, and implementing real solutions we can produce real results.</h3>
                  <p>Innovation has become endless in our interconnected society, it's a time full of opportunities. It's all right to be a consumer of these new digital services and technologies, but how this new age affects businesses that serve them in a way not seen before. Customer expectations continue to rise and there is an increasing demand for more personalized and targeted marketing. Investing in the capabilities of digital marketing can improve communication and create significant value inside your organization.</p>
                  <span className="break"></span>
                  <span className="break"></span>
                  <span className="break"></span>
                  <h4>Digital Consulting Services </h4>
                  <p>A plan that will work for you can be developed and applied. Here's how:</p>
                  <span className="break"></span>
                  <h5>Digital Strategies </h5>
                  <p>Use your data to create personalized and innovative solutions. It describes the digital strategy and puts together goods, skills, services and technologies. That's a guarantee of success.</p>
                  <h5>Customer Experience & Development </h5>
                  <p>Optimize how the business communicates with its clients, partners and staff. The importance of tailoring every experience and interaction is crucial in a world of many networks and communication styles. Since it is important to produce a result that meets and exceeds the needs of consumers and business goals.</p>
                  <h5>Marketing Operations </h5>
                  <p>With our specialists in marketing operations, redefine the production processes and digital marketing strategies. We consistently deliver advertising approaches.</p>
                  <h5>We can help you find your solution</h5>
                  <p>Let us work together to make your business, technology, and digital services succeed, with our efficiency, expert knowledge, and experience we can make your business plan a success. Through partnering with Monera Technologies we will find a solution that works for you. Discover your true potential.</p>
                  <span className="break"></span>
                  <span className="break"></span>
                  <span className="break"></span>
                  <hr /> 
                  <h3>Let’s Work Together! </h3>
                  <p><a href="">Discover your potential!</a></p>

                </div>
              </div>
            </div>
          </section>
        </Layout>
      )
  }
}

export default DigitalConsultingServices
